var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"manage_season_ticket_attributes","size":"lg","title":"Add New Season Ticket Tags","title-class":"font-18","centered":""},on:{"shown":_vm.modalShownCalls},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"light"},on:{"click":_vm.closeModal}},[_vm._v("Close")]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.tryingToSubmit},on:{"click":_vm.syncSeasonTicketAttributes}},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.tryingToSubmit),expression:"tryingToSubmit"}],attrs:{"small":""}}),_vm._v(" Submit ")],1)]},proxy:true}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(!_vm.showLoader)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.syncSeasonTicketAttributes.apply(null, arguments)}}},[_vm._l((_vm.attributes),function(attribute,index){return _c('div',{key:index,staticClass:"row mb-3"},[(_vm.subscriptionTagsTypes[attribute.name])?[_c('div',{staticClass:"col-sm-12 col-md-6 mb-sm-3 mb-md-0"},[_c('b-form-group',{attrs:{"label":"Tag Name","label-for":'attributename_'+index}},[_c('b-form-select',{staticClass:"form-select",class:{
                                     'is-invalid': _vm.submitted && _vm.$v.attributes.$each[index].name.$error,
                                },attrs:{"id":'attributename_'+index},model:{value:(attribute.name),callback:function ($$v) {_vm.$set(attribute, "name", $$v)},expression:"attribute.name"}},_vm._l((_vm.subscriptionTagsTypes),function(option){return _c('option',{key:option.name,domProps:{"value":option.name}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0),(_vm.submitted)?_c('validationMessages',{attrs:{"fieldName":'Tag Name',"validationName":_vm.$v.attributes.$each[index].name}}):_vm._e()],1)],1),_c('div',{staticClass:"col-sm-12 col-md-6 mb-sm-3 mb-md-0"},[_c('b-form-group',{staticClass:"d-inline-block w-50",attrs:{"label":"Tag Value","label-for":'attributevalue_'+index}},[(_vm.subscriptionTagsTypes[attribute.name].strict)?[_c('b-form-select',{staticClass:"form-select",class:{
                                    'is-invalid': _vm.submitted && _vm.$v.attributes.$each[index].value.$error,
                                },attrs:{"id":'attributevalue_'+index},model:{value:(attribute.value),callback:function ($$v) {_vm.$set(attribute, "value", $$v)},expression:"attribute.value"}},_vm._l((_vm.subscriptionTagsTypes[attribute.name].values),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),0),(_vm.submitted)?_c('validationMessages',{attrs:{"fieldName":'Tag Value',"validationName":_vm.$v.attributes.$each[index].value}}):_vm._e()]:[_c('b-form-input',{class:{
                                    'is-invalid': _vm.submitted && _vm.$v.attributes.$each[index].value.$error,
                                },attrs:{"id":'attributevalue_'+index},model:{value:(attribute.value),callback:function ($$v) {_vm.$set(attribute, "value", $$v)},expression:"attribute.value"}}),(_vm.submitted)?_c('validationMessages',{attrs:{"fieldName":'Tag Value',"validationName":_vm.$v.attributes.$each[index].value}}):_vm._e()]],2),(index > 0 || attribute.name != '' )?_c('div',{staticClass:"d-inline-block ms-3"},[_c('a',{staticClass:"text-danger",attrs:{"href":"javascript:void(0);","title":"Remove Tag"},on:{"click":function($event){return _vm.removeAdvertisementAttribute(index, attribute)}}},[_c('i',{staticClass:"uil uil-trash-alt font-size-18"})])]):_vm._e()],1)]:_vm._e()],2)}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('a',{staticClass:"btn btn-primary",on:{"click":_vm.addAttributeInput}},[_vm._v("Add New Tag")])])])],2):_c('div',{staticClass:"card-body d-flex align-items-center justify-content-center"},[_c('b-spinner',{attrs:{"large":""}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }